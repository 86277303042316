import { Box, Button, Flex, Heading, Divider } from '@chakra-ui/react';
import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import UpcomingCourses from '../components/home/upcoming-courses';
import FeaturedBooks from '../components/home/featured-resources';
import OurPartners from '../components/home/our-partners';
import SEO from '../components/seo';
import NewBook from '../components/home/new-book';
import AnxietyCoachBanner from '../components/home/AnxietyCoachBanner';

const IndexPage = ({ data }) => {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  const image = getImage(data.header.headerImage.gatsbyImage);
  return (
    <Flex flexDir="column" alignItems="center" justifyContent="center" w="100%">
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        alignItems={{ base: 'center', lg: 'center' }}
        w="100%"
        minH={{ base: '85vh', lg: '60vh' }}
        position="relative"
      >
        <Box
          display={{ base: 'block', md: 'none', lg: 'none', '2xl': 'none' }}
          height="12rem"
          w="100%"
          mb={8}
        >
          <Img
            alt=""
            image={data.header.headerImage.gatsbyImage}
            style={{ height: '100%' }}
          />
        </Box>
        <Flex
          flexDir="column"
          alignItems={{ base: 'start', lg: 'start' }}
          justifyContent={{ base: 'flex-end', lg: 'center' }}
          maxW={{ base: '95%', lg: '500px' }}
          mx={{ base: 'auto', lg: 0 }}
          w="100%"
          pr={{ base: 0, lg: 10 }}
          order={{ base: 2, lg: 0 }}
          mt={{ base: 0, md: '4rem', lg: 0 }}
        >
          <Heading
            as="h1"
            color="gray.900"
            fontSize={{ base: '2xl', xl: '3xl' }}
            textAlign={{ base: 'left', lg: 'left' }}
          >
            {data.header.heading}
          </Heading>
          <Heading
            as="h2"
            color="gray.600"
            fontSize={{ base: 16, xl: 18 }}
            fontWeight="400"
            mt={4}
            mb={{ base: 8, md: 6 }}
            lineHeight="1.5"
            textAlign={{ base: 'left', lg: 'left' }}
          >
            We help the community navigate how to raise the new generation, via
            evidence-based courses for professionals and parents.
            <br />
            <br />
            Founded by Michael Hawton (MAPS), former teacher and one of
            Australia’s leading child behaviour experts, Parentshop distils the
            best available research-based practical solutions, into digestible
            short courses that are easily transferrable to the classroom,
            workplace or home and are able to be implemented tomorrow.
            <br />
            <br />
            We believe that modest changes repeated over time, can make a big
            difference to the wellbeing and resilience of children.
          </Heading>
          <Flex>
            <Box mr={4}>
              <Link to="/for-professionals">
                <Button colorScheme="secondary">For professionals</Button>
              </Link>
            </Box>
            <Link to="/for-parents">
              <Button colorScheme="secondary" variant="ghost">
                For parents
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Flex w="100%" h="100%">
          <Box
            overflow="hidden"
            w="100%"
            borderRadius="xl"
            boxShadow="md"
            display={{ base: 'none', md: 'block' }}
          >
            <Img
              image={image}
              style={{ height: '100%' }}
              // imgStyle={{ objectFit: "contain" }}
              alt={data.header.headerImage.title}
            />
          </Box>
        </Flex>
      </Flex>
      <Divider my={8} />
      <AnxietyCoachBanner image={data.image.childImageSharp.gatsbyImageData} />
      <UpcomingCourses courses={data.courses.edges} />
      <NewBook />
      <FeaturedBooks />
      {/* <OurPartners /> */}
    </Flex>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    image: file(relativePath: { eq: "anxiety-coach-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    pageSeo: allContentfulPageSeo(filter: { pageName: { eq: "home" } }) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: contentfulEntry(contentful_id: { eq: "WDdiVIy0h3wvQahcysvOA" }) {
      ... on ContentfulPageHeader {
        id
        heading
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 1920)
        }
      }
    }
    courses: allContentfulCourse {
      edges {
        node {
          forParents
          courseCategory
          courseImage {
            title
            gatsbyImage(width: 1920)
          }
          courseName
          shortDescription {
            raw
          }
          slug
          subcategory
        }
      }
    }
  }
`;
