import { Button, chakra, Flex, Heading, Text } from '@chakra-ui/react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import React, { useEffect } from 'react';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import LinkButton from '../buttons/link-button';
import slugify from 'slugify';
import { FiArrowRight } from 'react-icons/fi';

export default function NewBook() {
  const [slug, setSlug] = React.useState<string>('');
  const data = useStaticQuery(graphql`
    query {
      book: shopifyProduct(title: { eq: "The Anxiety Coach" }) {
        title
        description
        media {
          ... on ShopifyMediaImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
        # images {
        #   localFile {
        #     childImageSharp {
        #       gatsbyImageData(width: 400)
        #     }
        #   }
        # }
      }
    }
  `);

  useEffect(() => {
    if (data?.book?.title) {
      const s = slugify(data?.book?.title, { lower: true });
      setSlug(`shop/${s}`);
    }
  }, [data]);

  return (
    <Flex w={'100%'} flexDir={'column'} pb={28} align={'center'} gap={6}>
      <Heading as={'h2'}>{data?.book?.title}</Heading>
      <Text>A new book from Michael Hawton</Text>
      <Flex boxShadow={'lg'} borderRadius={'lg'} overflow={'hidden'}>
        <Img
          image={
            data?.book?.media[0]?.image?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          alt={data?.book?.title}
        />
      </Flex>
      <Link to={slug}>
        <Button colorScheme={'secondary'} rightIcon={<FiArrowRight />}>
          Learn more
        </Button>
      </Link>
    </Flex>
  );
}
