import { Badge, Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage as Image, getImage } from 'gatsby-plugin-image';
import { FiArrowRight } from 'react-icons/fi';
import LinkButton from '../buttons/link-button';

export default function AnxietyCoachBanner({ image }) {
  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      w="full"
      py={12}
      gap={12}
      position={'relative'}
      align={'center'}
    >
      <Badge position={'absolute'} top={6} right={6} colorScheme={'green'}>
        New course
      </Badge>
      <Box
        w={{
          base: '85%',
          md: '35%',
        }}
      >
        <Image alt="Anxiety Coach" image={getImage(image)} />
      </Box>
      <Flex w={'full'} flexDir="column" gap={3}>
        <Heading size={'xl'}>Anxiety Coach &trade; for Parents</Heading>
        <Text>
          Equipping parents with practical strategies and support to help their
          children effectively manage anxiety.
        </Text>

        <LinkButton text="Learn more" link="parents/anxiety-coach-parents" />
      </Flex>
    </Flex>
  );
}
