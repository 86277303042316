import { Box, Button, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import LinkButton from '../buttons/link-button';
import ProductPreview from '../shop/product-preview';
import slugify from '../../utils/slugify';
import { getImage } from 'gatsby-plugin-image';

function FeaturedResources({
  title = 'Featured resources',
  description = 'Hand-picked resources packed with practical advice, carefully curated by the Parentshop team.',
}) {
  const data = useStaticQuery(graphql`
    query {
      books: allShopifyProduct(
        filter: { productType: { eq: "Book" } }
        limit: 4
      ) {
        nodes {
          vendor
          id
          title
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
          }
          productType
          media {
            ... on ShopifyMediaImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Flex
      py={{ base: 16, lg: 24 }}
      // bgGradient="linear(blue.800 0%,  blue.700 100%)"
      bgColor="white"
      w="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        flexDir="column"
        maxW="1280px"
        w={{ base: '90%', '2xl': '100%' }}
        alignItems="start"
      >
        <Heading color="gray.900">{title}</Heading>
        <Text color="gray.600" mt={5} fontSize={{ base: 'md', xl: 'lg' }}>
          {description}
        </Text>
        <Box mt={4}>
          <LinkButton text="Go to Shop" link="shop" />
        </Box>
        <Grid
          gridTemplateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={{ base: 16, lg: 32 }}
          w="100%"
          my={{ base: 12, md: 16, lg: 20 }}
        >
          {data.books.nodes.map((product, index) => {
            const slug = slugify(product.title);
            return (
              <ProductPreview
                key={index}
                name={product.title}
                type={product.productType}
                image={getImage(
                  product.media[0].image.localFile.childImageSharp
                    .gatsbyImageData
                )}
                brand={product.vendor}
                price={product.priceRangeV2.maxVariantPrice.amount}
                path={`${slug}`}
              />
            );
          })}
        </Grid>
      </Flex>
    </Flex>
  );
}

export default FeaturedResources;
